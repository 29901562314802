<template>
    <div class="articleListContainer">
        <v-list class="recapContainer">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class ="textSize20">
                        {{ selectedClient.fullName}}
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-list-item-subtitle class ="textSize20 font-weight-bold">
                        <span v-if="showPrices"> {{ (allTotal).toFixed(2) }} € | </span> {{ allQuantity }} {{ singleOrMultiple }}
                    </v-list-item-subtitle>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-card
            class="article-list"
        >
            <template v-if="scrollerItems.length === 0">
                <v-card>
                    <v-card-title
                        class="text-h5 grey darken-2 white--text font-weight-bold"
                        primary-title
                    >
                        No Sales
                    </v-card-title>
                </v-card>
            </template>
            <DynamicScroller
                class="scroller"
                :items="scrollerItems"
                :min-item-size="50"
                key-field="id"
            >
                <template v-slot="{ item, index, active }">
                    <DynamicScrollerItem
                        :item="item"
                        :active="active"
                        :data-index="index"
                    >
                        <template v-if="item.type === 'ArticleListItem'">
                            <v-divider />

                            <articleListItem
                                :item="item.article"
                                :order="getOrder(item.article)"
                                :searchArticle="searchArticle"
                                :showPrices="showPrices"
                                @updateCount="onUpdateCount(item.article, $event)"
                                @updateCount:single="onUpdateCountSingle(item.article, $event)"
                                @updatePackage="onUpdatePackage(item.article, $event)"
                                @updateComment="onUpdateComment(item.article, $event)"
                            />
                        </template>
                        <template v-if="item.type === 'GroupHeader'">
                            <v-card>
                                <v-card-title
                                    class="text-h5 grey darken-2 white--text font-weight-bold"
                                    primary-title
                                >
                                    {{ item.text }}
                                </v-card-title>
                            </v-card>
                        </template>
                    </DynamicScrollerItem>
                </template>
            </DynamicScroller>
        </v-card>
        <v-snackbar
            v-model="notification"
            color="warning"
            bottom
        >
            {{ $t("articleList.nothing") }}
            <v-btn
                dark
                text
                @click="notification = false"
            >
                Close
            </v-btn>
        </v-snackbar>
        <deleteDialog
            @deleteOrder="deleteOrder($event)"
            :showPrices="showPrices"
            ref="deleteDialog"
        />
        <addArticleDialog
            :articles="allArticles"
            :showPrices="showPrices"
            @addArticle="onAddArticle($event)"
            ref="addArticleDialog"
        />
        <recapDialog
            :articles="allArticles"
            :orders="orders.order"
            :selectedClient="selectedClient"
            :valid="valid"
            :signatureValidate="orders.options.signatureValidate"
            :isEdit="isEdit"
            :showPrices="showPrices"
            @save="save($event)"
            @validate="onValidate($event)"
            ref="recapDialog"
        />
        <v-footer
            class="articleListFooter"
            height="60"
        >
            <v-spacer />
            <v-btn
                dark
                @click="openRecapDialog()"
            >
                {{ $t("articleList.save") }}
            </v-btn>
            <v-spacer
                v-if="this.orderID !== null"
            />
            <v-btn
                v-if="this.orderID !== null"
                dark
                @click="openDeleteDialog()"
            >
                {{ $t("articleList.remove") }}
            </v-btn>
            <v-spacer />
        </v-footer>
    </div>
</template>

<script>
import moment from 'moment'
import articleListItem from '../components/articleListItem.vue'
import uuidv4 from 'uuid/v4'
import _ from 'lodash'
import deleteDialog from '../components/deleteDialog'
import addArticleDialog from '../components/addArticleDialog'
import recapDialog from '../components/recapDialog'
import Vue from 'vue'

export default {
    name: 'editList',
    components: {
        articleListItem,
        deleteDialog,
        addArticleDialog,
        recapDialog
    },
    props: {
        articles: {
            type: Array,
            default () {
                return []
            }
        },
        allArticles: {
            type: Array,
            default () {
                return []
            }
        },
        orders: {
            type: Object,
            default () {
                return {}
            }
        },
        showButton: {
            type: Boolean,
            default () {
                return false
            }
        },
        showPrices: {
            type: Boolean,
            default () {
                return false
            }
        },
        selectedClient: {
            type: Object,
            default () {
                return {}
            }
        },
        orderID: {
            type: Number,
            default () {
                return null
            }
        },
        searchArticle: {
            type: String,
            default () {
                return ''
            }
        },
        validation: {
            type: Boolean,
            default () {
                return false
            }
        }
    },
    data () {
        return {
            date: '',
            notification: false,
            valid: false,
            isEdit: true,
            propOrders: {}
        }
    },
    watch: {
        validation: function (newValidation, oldValidation) {
            if (newValidation === true) {
                this.openRecapDialog()
            }
        }
    },
    computed: {
        filterOrder () {
            return this.orders.order.filter((item) => {
                return item.quantity > 0 || item.quantity < 0 || item.comment
            })
        },
        sortedArticles () {
            return _.orderBy(this.articles, ['fullDesignation', 'fullName'])
        },
        filteredArticles () {
            if (!this.searchArticle) {
                return this.sortedArticles
            } else {
                return this.sortedArticles.filter((article) => {
                    return article.fullName.toLowerCase().includes(this.searchArticle.toLowerCase()) ||
                    article.article.toLowerCase().includes(this.searchArticle.toLowerCase()) ||
                    article.fullDesignation.toLowerCase().includes(this.searchArticle.toLowerCase())
                })
            }
        },
        scrollerItems () {
            const scrollerItems = []
            let currentHeader = null
            this.filteredArticles.forEach(article => {
                if (currentHeader !== article.fullDesignation) {
                    scrollerItems.push({
                        type: 'GroupHeader',
                        id: 'GroupHeader:' + article.article,
                        text: article.fullDesignation
                    })
                    currentHeader = article.fullDesignation
                }
                scrollerItems.push({
                    type: 'ArticleListItem',
                    id: 'ArticleListItem:' + article.article,
                    article
                })
            })
            return scrollerItems
        },
        allTotal () {
            return this.orders.order.reduce((allTotal, order) => {
                let unitPrice = 0
                const article = this.articleMap.get(order.article)

                if (article) {
                    unitPrice = article.unitPrice
                }
                order.quantity.forEach((quantity) => {
                    allTotal = +allTotal + ((+quantity * +order.package.unitQuantity) * unitPrice)
                })
                return allTotal
            }, 0)
        },
        allQuantity () {
            return this.orders.order.reduce((allQuantity, order) => {
                order.quantity.forEach((quantity) => {
                    allQuantity = +allQuantity + (+quantity * +order.package.unitQuantity)
                })
                return allQuantity
            }, 0)
        },
        singleOrMultiple () {
            if (this.allQuantity > 1) {
                return 'Items'
            }
            return 'Item'
        },
        articleMap () {
            const articleMap = new Map()

            this.allArticles.forEach(function (item) {
                articleMap.set(item.article, item)
            })

            return articleMap
        }
    },
    created () {
        this.date = moment().format('YYYY-MM-DD HH:mm:ss')
        if (this.orders.options.valid) {
            this.valid = this.orders.options.valid
        }
        this.propOrders = this.orders
    },
    methods: {
        formatPrice (value) {
            const val = value.toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        },
        openDeleteDialog () {
            const deleteDialog = this.$refs.deleteDialog
                .open()
            return deleteDialog
        },
        openAddArticleDialog () {
            const addArticleDialog = this.$refs.addArticleDialog
                .open()
            return addArticleDialog
        },
        openRecapDialog () {
            const recapDialog = this.$refs.recapDialog
                .open()
            return recapDialog
        },
        deleteOrder () {
            const oldOrders = JSON.parse(localStorage.getItem('orders'))
            if (oldOrders) {
                if (this.orderID !== null) {
                    oldOrders.splice(this.orderID, 1)
                    localStorage.setItem('orders', JSON.stringify(oldOrders))
                    window.location = '#/'
                }
            }
        },
        save ({ valid, signature, selectedPayment }) {
            const getOrders = []
            for (const order of this.orders.order) {
                if (order.quantity > 0 || order.quantity < 0 || order.comment) {
                    getOrders.push(order)
                }
            }
            const options = {
                valid: valid,
                saved: true
            }

            if (valid === true) {
                options.signatureValidate = true
            }

            if (signature) {
                options.signature = signature
            }

            if (selectedPayment) {
                options.selectedPayment = selectedPayment
            }

            let source = {}
            if (this.orders.source) {
                source = this.orders.source
            }

            let addresseLivraison = null
            if (this.selectedClient.adresse_livraison) {
                addresseLivraison = this.selectedClient
            }

            let newOrder = {
                fullName: this.selectedClient.fullName,
                date: this.date,
                price: this.allTotal,
                street: this.selectedClient.street,
                country: this.selectedClient.country,
                adresse_livraison: addresseLivraison,
                clientInfo: this.selectedClient,
                block: false,
                uuid: uuidv4(),
                order: getOrders,
                source: source,
                options: options
            }

            const oldOrders = JSON.parse(localStorage.getItem('orders'))
            if (oldOrders !== null) {
                if (this.orderID !== null) {
                    oldOrders.splice(this.orderID, 1)
                }
                oldOrders.push(newOrder)
                localStorage.setItem('orders', JSON.stringify(oldOrders))
            } else {
                newOrder = [newOrder]
                localStorage.setItem('orders', JSON.stringify(newOrder))
            }

            if (getOrders.length === 0) {
                this.notification = true
                setTimeout(function () { window.location = '#/' }, 2000)
            } else {
                window.location = '#/'
            }
        },
        getOrder (item) {
            let order = this.propOrders.order.find(order => order.article === item.article)
            if (!order) {
                let quantityCount = 1
                if (item.deliveryDateList && item.deliveryDateList.length !== 0) {
                    quantityCount = item.deliveryDateList.length
                }
                const quantity = []
                for (let i = 0; i < quantityCount; i++) {
                    quantity.push(0)
                }
                order = {
                    article: item.article,
                    quantity,
                    package: item.packages[0],
                    comment: ''
                }
                this.propOrders.order.push(order)
            }
            return order
        },
        onAddArticle ({ item, count }) {
            const order = this.getOrder(item)
            Vue.set(order.quantity, 0, count)
        },
        onUpdateCount (item, { index, count }) {
            const order = this.getOrder(item)
            Vue.set(order.quantity, index, count)
        },
        onUpdateCountSingle (item, { count, optional }) {
            const order = this.getOrder(item)
            Vue.set(order.quantity, 0, count)
            if (optional) {
                order.optional = optional
            }
        },
        onUpdateComment (item, { comment }) {
            const order = this.getOrder(item)
            order.comment = comment
        },
        onUpdatePackage (item, { packageID }) {
            const order = this.getOrder(item)
            order.package = item.packages[packageID]
        },
        onValidate ({ valid }) {
            this.valid = valid
        }
    }
}
</script>
<style>
.article-list {
    flex-grow: 1;
    overflow: auto;
    padding: 0;
}
.centered-input input {
    text-align: center;
}
.scroller {
    height: 100%;
}
.articleListContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
}
.recapContainer {
    flex-shrink: 0;
    border-bottom: solid 1px rgba(0,0,0,0.12);
}
.articleListFooter {
    flex-shrink: 0;
    height: 60px;
    flex-basis: 60px;
    min-height: 60px;
}
.textSize20 {
    font-size: 20px;
}
</style>
