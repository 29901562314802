<template>
    <div>
        <v-app
            id="inspire"
            class="ventesapp"
        >
            <v-layout
                class="summaryContainer"
                column
                fill-height
            >
                <header>
                    <div class="gradient" />
                    <v-app-bar
                        color="black"
                        dark
                    >
                        <v-btn
                            icon
                            @click="checkUnsavedChanges"
                        >
                            <v-icon>arrow_back</v-icon>
                        </v-btn>
                        <span
                            class="text-h6"
                            v-if="!searchBar"
                        >
                            {{ $t("edit.edit") }}
                        </span>
                        <v-text-field
                            v-if="searchBar"
                            :value="searchArticle"
                            @input="onInput"
                            clearable
                            placeholder="Search for Articles"
                            class="searchBar"
                            ref="search"
                        />
                        <v-spacer />
                        <v-btn
                            v-if="switchList === false && hasSales === true"
                            @click="switchListing"
                            :disabled="loadingSwitch"
                        >
                            Switch to all Articles
                        </v-btn>
                        <v-btn
                            v-if="switchList === true && hasSales === true"
                            @click="switchListing"
                            :disabled="loadingSwitch"
                        >
                            Switch to Sales
                        </v-btn>
                        <v-btn
                            icon
                            @click="openSearch"
                        >
                            <v-icon>search</v-icon>
                        </v-btn>
                    </v-app-bar>
                    <div
                        v-if="loading"
                        class="centerLoading"
                    >
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            class="loadingscreen"
                            color="red"
                        />
                        <div>
                            Please Wait
                        </div>
                    </div>
                </header>
                <editList
                    class="editList"
                    :articles="switcher"
                    :allArticles="articles"
                    :orders="editOrder.order"
                    :selectedClient="editOrder.order.clientInfo"
                    :orderID="editOrder.id"
                    :searchArticle="searchArticle"
                    :showButton="hasSales"
                    :validation="validation"
                    :showPrices="showPrices"
                />
                <v-dialog
                    v-model="exitDialog"
                    persistent
                    max-width="350"
                >
                    <v-card>
                        <v-card-text
                            center
                        >
                            <h1>
                                {{ $t("summary.unSavedChanges") }}
                            </h1>
                            <h2>
                                {{ $t("summary.sure") }}
                            </h2>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                color="primary"
                                width="160"
                                @click.native="exitDialog = false"
                            >
                                <icon
                                    name="fa/solid/times"
                                    class="barIcon"
                                    color="white"
                                />
                            </v-btn>
                            <v-btn
                                color="primary"
                                width="160"
                                @click.native="agree()"
                            >
                                <icon
                                    name="fa/solid/check"
                                    class="barIcon"
                                    color="white"
                                />
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-layout>
        </v-app>
    </div>
</template>
<script>
import editList from '../components/editList'
import localforage from 'localforage'
import debounce from 'lodash/debounce'
import Icon, { register as registerIcons } from '@wisol/icons'
import CheckIcon from '@wisol/icons/src/icons/fa/solid/check'
import TimesIcon from '@wisol/icons/src/icons/fa/solid/times'

registerIcons(CheckIcon, TimesIcon)

export default {
    name: 'Edit',
    components: {
        editList,
        Icon
    },
    data () {
        return {
            exitDialog: false,
            changes: -1,
            hasChanges: false,
            editOrder: {},
            prices: [],
            articles: [],
            packages: [],
            sales: [],
            loading: true,
            searchBar: false,
            searchArticle: '',
            switchList: false,
            hasSales: false,
            validation: false,
            showPrices: false,
            loader: null,
            loadingSwitch: false
        }
    },
    watch: {
        orders: {
            deep: true,
            handler () {
                this.changes++
                if (this.changes > 0) {
                    this.hasChanges = true
                }
            }
        },
        loader () {
            const l = this.loader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 1000)
            this.loadingSwitch = false

            this.loader = null
        }
    },
    computed: {
        filteredArticles () {
            return this.articles.filter((item) => {
                return item.sales || item.quantity !== 0
            })
        },
        switcher () {
            if (this.switchList === false && this.hasSales === true) {
                return this.filteredArticles
            } else {
                return this.articles
            }
        },
        filteredPrices () {
            if (this.editOrder.order.clientInfo.priceCategory === undefined || this.editOrder.order.clientInfo.priceCategory === '' || this.editOrder.order.clientInfo.priceCategory === null) {
                return this.prices
            } else {
                return this.prices.filter((price) => {
                    return price.priceCategory === this.editOrder.order.clientInfo.priceCategory
                })
            }
        },
        filteredSales () {
            if (this.editOrder.order.clientInfo.client === '' || this.editOrder.order.clientInfo.client === null) {
                return this.sales
            } else {
                return this.sales.filter((sale) => {
                    return sale.client === this.editOrder.order.clientInfo.client
                })
            }
        },
        pricesMap () {
            const pricesMap = new Map()

            this.filteredPrices.forEach(function (item) {
                pricesMap.set(item.article, item)
            })

            return pricesMap
        },
        packagesMap () {
            const packagesMap = new Map()

            this.packages.forEach(function (item) {
                const map = packagesMap.get(item.article)
                if (map) {
                    item.id = map.length
                    packagesMap.get(item.article).push(item)
                } else {
                    packagesMap.set(item.article, [])
                    item.id = 0
                    packagesMap.get(item.article).push(item)
                }
            })

            return packagesMap
        },
        salesMap () {
            const salesMap = new Map()

            this.filteredSales.forEach(function (item) {
                salesMap.set(item.article, item)
            })

            return salesMap
        }
    },
    created () {
        this.loadOrder()
        localforage.getItem('remoteSettings', (err, value) => {
            if (err !== null) {
                this.remoteSettings = []
                // eslint-disable-next-line
                    console.log(err)
            } else {
                this.remoteSettings = value
                for (const prop in value) {
                    value[value[prop].name] = value[prop].value
                    delete value[prop]
                }
            }
            if (this.remoteSettings.ORDER_PROPOSAL_SHOW_PRICES) {
                this.showPrices = this.remoteSettings.ORDER_PROPOSAL_SHOW_PRICES
            }
        })
    },
    methods: {
        agree () {
            this.exitDialog = false
            window.location = '#/'
        },
        switchListing () {
            this.switchList = !this.switchList
            this.loadingSwitch = true
            this.loader = 'loading'
        },
        checkUnsavedChanges () {
            if (this.hasChanges) {
                this.exitDialog = true
            } else {
                window.location = '#/'
            }
        },
        loadOrder () {
            localforage.getItem('editOrder', (err, value) => {
                if (err !== null) {
                    this.editOrder = {}
                    // eslint-disable-next-line
                    console.log(err)
                } else {
                    this.editOrder = value
                }
            })
                .then(() => {
                    this.loadPrices()
                })
        },
        loadPrices () {
            localforage.getItem('prices', (err, value) => {
                if (err !== null) {
                    this.prices = []
                    // eslint-disable-next-line
                    console.log(err)
                } else {
                    this.prices = value
                }
            })
                .then(() => {
                    this.loadPackages()
                })
        },
        loadPackages () {
            localforage.getItem('packages', (err, value) => {
                if (err !== null) {
                    this.packages = []
                    // eslint-disable-next-line
                    console.log(err)
                } else {
                    this.packages = value
                }
            })
                .then(() => {
                    this.loadSales()
                })
        },
        loadSales () {
            localforage.getItem('sales', (err, value) => {
                if (err !== null) {
                    this.sales = []
                    // eslint-disable-next-line
                    console.log(err)
                } else {
                    this.sales = value
                }
            })
                .then(() => {
                    this.loadArticles()
                })
        },
        loadArticles () {
            localforage.getItem('articles', (err, articles) => {
                if (!err) {
                    articles.forEach(article => {
                        const defaultArticle = []
                        const price = this.pricesMap.get(article.article)
                        const packages = this.packagesMap.get(article.article)
                        const sales = this.salesMap.get(article.article)

                        if (price) {
                            article.unitPrice = price.unitPrice
                        } else {
                            article.unitPrice = 0
                        }

                        if (packages) {
                            article.packages = packages
                        }

                        if (sales) {
                            article.sales = sales
                            this.hasSales = true
                        }

                        article.quantity = 0

                        if (article.deliveryDateList) {
                            if (article.deliveryDateList.length !== 0) {
                                article.deliveryDateList = JSON.parse(article.deliveryDateList)

                                for (const deliveryDate of article.deliveryDateList) {
                                    defaultArticle.push({
                                        date: deliveryDate,
                                        selected: false,
                                        quantity: 0
                                    })
                                }
                                article.deliveryDateList = defaultArticle
                                article.comment = ''
                            }
                        }
                        Object.freeze(article)
                    })
                    this.articles = articles
                } else {
                    this.articles = []
                    // eslint-disable-next-line
                    console.log(err)
                }
                this.loading = false
                this.validation = true
            })
        },
        openSearch () {
            this.searchBar = true
            this.$nextTick(() => this.$refs.search.focus())
        },
        onInput: debounce(function (searchArticle) {
            this.searchArticle = searchArticle
        }, 300)
    }
}
</script>
<style>
.centerLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0,0,0,0.2);
}
.loadingscreen {
    width: 100;
    height: 100;
}
.summaryContainer{
display: flex;
flex-direction: column;
flex-wrap: nowrap;
}
.summaryContainer header {
flex-shrink: 0;
display: flex;
flex-direction: column;
flex-wrap: nowrap;
}
.summaryContainer .editList {
overflow: auto;
flex-grow: 1;
}
</style>
